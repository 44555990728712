import { MouseEvent } from "react";
import { signInWithPopup, onAuthStateChanged, UserCredential } from 'firebase/auth';

import { firebaseAuth, firebaseProvider } from '../../firebase';
import { CurrentUser } from "../../contexts/UserContext";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { Button } from "flowbite-react";


type LoginButtonProps = {
  onLogin: (user: CurrentUser) => void;
  onError?: (error: Error) => void;
}

export const LoginButton = ({ onLogin, onError }:LoginButtonProps) => {
  const userAuthHandler = (event: MouseEvent<HTMLButtonElement>) => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        onLogin({
          email: user.email,
          name: user.displayName,
          photoURL: user.photoURL,
          uid: user.uid
        });
      }
      else {
        signInWithPopup(firebaseAuth, firebaseProvider).then(({ user }: UserCredential) => {
          onLogin({
            email: user.email,
            name: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid
          });
        }).catch((error) => {
          if (onError) {
            onError(error);
          }
        });
      }
    })
  }

  return (
    <div>
      <Button onClick={userAuthHandler} className="p-0">
        <ArrowRightEndOnRectangleIcon className="size-6" />
      </Button>
    </div>
  );
}

