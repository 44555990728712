import { collection, addDoc, getDocs, deleteDoc, doc } from "firebase/firestore";
import { firestore } from '../firestore';

export type LogItem = {
  ID?: string,
  datetime: Date,
  actionStatus: boolean,
  uid?: string,
};

/*
*
*
*/
export const addLog = (log: LogItem) => {
  return addDoc(collection(firestore, "logs"), log);
}

/*
*
*
* */
export const getLogs = async (): Promise<LogItem[]> => {

  const items: LogItem[] = [];

  let querySnapshot = await getDocs(collection(firestore, "logs"));
  querySnapshot.forEach((doc) => {
    const item = doc.data();

    items.push({
      ID: doc.id,
      datetime: item.datetime.toDate(),
      actionStatus: item.actionStatus,
    });
  });

  return items;
}

export const getLog = (): LogItem => {

  return {
    datetime: new Date(),
    actionStatus: true,
  };
}

export const updateLog = (log: LogItem) => {

}

export const deleteLog = (ID: string) => {
  const logRef = doc(firestore, "logs", ID);

 return deleteDoc(logRef);
}

export type onUpdateProps = {
  callback: (logs: LogItem[]) => void;
}

