import { createContext } from 'react';

export type CurrentUser = {
  email: string|null;
  name: string|null;
  photoURL: string|null;
  uid?: string;
};

export const UserContext = createContext<CurrentUser|null>(null);
