import { deleteLog, getLogs, LogItem } from "../../services/LogsService";
import React, { useEffect, useState, useContext } from "react";
import { eventEmitter, EVENTS } from "../../services/EventEmitterService";
import { LightBulbIcon, TrashIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../contexts/UserContext";


const DateFormat = ({ datetime }: { datetime: Date }) => {
  return (
    <data>{datetime.toLocaleDateString('uk-UK', { dateStyle: 'medium' })}</data>
  )
}

const TimeFormat = ({datetime}: { datetime: Date }) => {
  return (
    <time className="font-bold">{datetime.toLocaleTimeString('uk-UK', { timeStyle: 'short' })}</time>
  )
}

export const LogList = () => {

  const currentUser = useContext(UserContext);

  const [logs, setLogs] = useState<LogItem[]>([]);

  eventEmitter.on(EVENTS.LOG_ADDED, () => {
    getLogs().then((logs) => setLogs(logs));
  })

  useEffect(() => {
    getLogs().then((logs) => setLogs(logs));
  }, [currentUser]);

  logs.sort((a, b) => {
    return b.datetime.getTime() - a.datetime.getTime();
  })

  const deleteLogItemHandler = (logId: string) => {
    deleteLog(logId).then(() => {
      getLogs().then((logs) => setLogs(logs));
    })
  }

  return (
    <div className="mt-2">
      <h1 className="text-xl font-bold p-2 text-center uppercase">Logs</h1>

      <ul className="w-full border border-b-0">
        {logs.map((log) => {
          return (
            <li key={log.ID} className="flex flex-row justify-between border-b p-2">
              <span className="flex flex-row gap-2">
                <DateFormat datetime={log.datetime} />
                <TimeFormat datetime={log.datetime} />
              </span>

              <span className="flex flex-row gap-2">
                <LightBulbIcon className={`size-6 ${log.actionStatus ? 'text-green-500' : 'text-red-500'}`}/>

                { currentUser && currentUser.uid === 'KyUm5rFrguNAvgrei1MhcNMZcQt1' &&
                  <TrashIcon
                    className="size-5 text-red-500 hover:text-gray-500 cursor-pointer"
                    onClick={ () => log.ID && deleteLogItemHandler(log.ID) }
                  />
                }
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  );


}
