import { TextInput, Select, Button } from "flowbite-react";

import { LogItem, addLog } from "../../services/LogsService";
import { useContext, useState } from "react";
import { eventEmitter, EVENTS } from "../../services/EventEmitterService";
import { UserContext } from "../../contexts/UserContext";

type LogFormProps = {
  onSubmit: (result: boolean) => void;
  logItem?: LogItem;
}

type LogFormData = {
  datetime: Date;
  actionStatus: boolean;
}

//function format datetime to yyyy-MM-ddThh:mm
const dateTimeFormat = (date: Date, format: 'date' | 'time' | 'full'): string => {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  let hour = date.getHours().toString().padStart(2, '0');
  let minute = date.getMinutes().toString().padStart(2, '0');

  if (format === 'date') {
    return `${year}-${month}-${day}`;
  }

  if (format === 'time') {
    return `${hour}:${minute}`;
  }

  return `${year}-${month}-${day}T${hour}:${minute}`;
}

function roundMinutes(date: Date) {
  let roundedMinutes = Math.round(date.getMinutes() / 5) * 5;
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);

  return date.getMinutes();
}

export const LogForm = (props: LogFormProps) => {
  const currentUser = useContext(UserContext);

  const [formData, setFormData] = useState<LogFormData>({
    actionStatus: false,
    datetime: new Date(),
  });

  const [date, setDate] = useState<Date>(formData.datetime);
  const [hour, setHour] = useState<string>(formData.datetime.getHours().toString());
  const [minutes, setMinutes] = useState<number>(roundMinutes(formData.datetime));

  const hourList = Array.from({length: 24}, (_, i) => i);
  const minutesList = Array.from({length: 12}, (_, i) => i * 5);

  return (
    <form className="flex flex-col gap-4" onSubmit={
      (event) => {
        event.preventDefault();

        addLog({
          datetime: new Date(`${dateTimeFormat(date, 'date')} ${hour}:${minutes}`),
          actionStatus: formData.actionStatus,
          uid: currentUser?.uid,
        })

        .then((result) => {
          props.onSubmit(true);
          eventEmitter.emit(EVENTS.LOG_ADDED);
        })

        .catch((error) => {
          props.onSubmit(false);
        })
      }
    }>
      <div className="datetime flex flex-row justify-stretch">
        <div className="w-1/3">
          <label htmlFor="date" className="font-bold">Date</label>
          <TextInput type="date" id="date" value={dateTimeFormat(date, 'date')} onChange={ (e) => setDate(new Date(e.target.value)) } />
        </div>

        <div className="w-1/3">
          <label htmlFor="hours" className="font-bold">Hours</label>
          <Select id="hours" value={hour} onChange={(e) => setHour(e.target.value)}>
            {hourList.map((value: number) => <option key={value} value={value}>{value}</option>)}
          </Select>
        </div>

        <div className="w-1/3">
          <label htmlFor="minutes" className="font-bold">Minutes</label>
          <Select id="minutes" value={minutes} onChange={(e) => setMinutes(parseInt(e.target.value))}>
            {minutesList.map((value: number) => <option key={value} value={value}>{value}</option>)}
          </Select>
        </div>
      </div>
      <div>
        <label htmlFor="actionStatus" className="font-bold">Action Status</label>
        <Select required id="actionStatus" value={formData.actionStatus ? 'true' : 'false'} onChange={(event) => {
          setFormData({
            ...formData,
            actionStatus: event.target.value === 'true',
          });
        }}>
          <option value="true">Turn On</option>
          <option value="false">Turn Off</option>
        </Select>
      </div>
      <div>
        <Button color="dark" type="submit">Submit</Button>
      </div>
    </form>
  );
}
