import React, { useEffect, Suspense } from 'react';
import './index.css'
import { Button, Navbar } from "flowbite-react";
import { LogForm } from "./components/LogForm";
import { CurrentUser, UserContext } from "./contexts/UserContext";
import { LoginButton } from "./components/LoginButton";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./firebase";
import { LogList } from "./components/LogList";
import { ArrowRightEndOnRectangleIcon, ArrowRightStartOnRectangleIcon, PlusIcon } from "@heroicons/react/24/outline";



const App = () => {

  const [user, setUser] = React.useState<CurrentUser|null>(null);
  const [showForm, setShowForm] = React.useState<boolean>(false);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUser({
          email: user.email,
          name: user.displayName,
          photoURL: user.photoURL,
          uid: user.uid
        });
      }
    })
  }, [])

  return (
    <UserContext.Provider value={user}>
    <div className="flex max-w-md flex-col gap-4 m-auto">
      <Navbar>
        { user && user.photoURL && <img src={user.photoURL} className="h-8 w-8 rounded-full" /> }
        { user && <Button onClick={ () => setShowForm(true) }><PlusIcon className="size-6" /></Button>}
        { user ? <Button onClick={() => setUser(null)}><ArrowRightStartOnRectangleIcon className="size-6" /></Button> : <LoginButton onLogin={setUser} />}

      </Navbar>

      {showForm && <LogForm onSubmit={() => setShowForm(false)} />}

      <Suspense fallback={<div>Loading...</div>}>
        { user && <LogList /> }
      </Suspense>

    </div>
    </UserContext.Provider>
  );
};

export default App;
